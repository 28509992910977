<template>
  <!-- 身份认证 -->
  <div class="main-wrap">
    <!-- 实名认证 -->
    <div class="box box1" v-if="showPage">
      <div class="box_title">
        <span class="title_info1">实名认证</span>
        <span class="title_info2">（认证后无法修改）</span>
      </div>
      <div class="box_hint" v-if="!isAuthentication">
        <img class="hint_icon" src="@/assets/image/knowledgeMall/success.png" alt="提示">
        <span class="hint_txt">信息将用于核实您的真实身份，与账号唯一绑定，验证成功后不可修改；我们对信息进行严格保密，请放心使用。</span>
      </div>
      <div class="box_label" v-else>
        <img src="@/assets/image/personalCenter/20220927164630.png" alt="">
      </div>
      <div class="box_input">
        <a-form :form="form">
          <a-form-item  label="姓名">
            <span v-if="!isEdit || isAuthentication" class="item_con">{{identityForm.name}}</span>
            <a-input
              v-else
              v-decorator="[
                'name',
                { rules: [
                  { required: true, message: '请输入姓名' },
                ] },
              ]"
              :disabled="isAuthentication"
              placeholder="请输入姓名"
            />
            <!-- { pattern: $regular.name.reg, message: $regular.name.msg } -->
          </a-form-item>
          <template v-if="!isEdit || isAuthentication">
            <a-form-item :label="identityForm.idType == 1 ? '身份证': identityForm.idType == 2 ? '港澳台居住证': identityForm.idType == 3 ? '护照' : ''">
              <span class="item_con">{{identityForm.idNo}}</span>
            </a-form-item>
          </template>
          <template v-else>
            <a-form-item class="item_idType" label="证件信息：">
              <a-form-item class="idType_item">
                <a-select 
                  :disabled="isAuthentication"
                  placeholder="请选择证件类型"
                  style="width: 151px" 
                  @change="changeIdType"
                  v-decorator="['idType']">
                  <a-icon slot="suffixIcon" type="caret-down" style="fontSize: 15px;" />
                  <a-select-option
                    v-for="(item, index) in identityArray" 
                    :key="index"
                    :value="item.id">{{item.name}}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item class="idType_item">
                <a-input
                  v-decorator="[
                    'idNo',
                    { rules: idCardVerify },
                  ]"
                  :disabled="isAuthentication"
                  placeholder="请输入证件号码"
                  @change="changeIdNo"
                />
              </a-form-item>
            </a-form-item>
          </template>
          <a-form-item label="性别：">
            <span v-if="!isEdit || isAuthentication" class="item_con">{{identityForm.sex=='1' ? '男' : identityForm.sex=='2' ? '女' : ''}}</span>
            <a-radio-group 
              v-else
              :disabled="sexDisabled || isAuthentication"
              name="radioGroup" 
              v-decorator="[
                'sex',
                { rules: [{ required: true, message: '请选择性别' }] },
              ]">
              <a-radio 
                :value="item.id" 
                v-for="(item, index) in sexArray" 
                :key="index">{{item.name}}</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <!-- 个人信息 -->
    <div class="box box2" v-if="showPage">
      <div class="box_title">
        <span class="title_info1">个人信息</span>
      </div>
      <div class="box_edit" v-if="!isEdit" @click="editIdentityAuthen">
        <img class="edit_icon" src="@/assets/image/personalCenter/2022072701.png" alt="编辑">
        <span class="edit_txt">编辑信息</span>
      </div>
      <div class="box_input">
        <a-form :form="form">
          <a-form-item class="form_item1" label="我的身份：">
            <template v-if="!isEdit">
              <span class="item_con">{{identityForm.userType==1 ? '家长' : identityForm.userType==2 ? '从业者（含学生、科研人员等）' : '家长、从业者（含学生、科研人员等）'}}</span>
            </template>
            <template v-else>
              <span class="item_info">（可选择双重身份）</span>
              <a-row>
                <a-col>
                  <a-checkbox-group 
                    @change="changeType"
                    v-decorator="[
                      'type',
                      { rules: [{ required: true, message: '请选择身份类型' }] },
                    ]">
                    <a-checkbox value="1">从业者（含学生、科研人员等）</a-checkbox>
                    <a-checkbox value="2">家长</a-checkbox>
                  </a-checkbox-group>
                </a-col>
              </a-row>
            </template>
          </a-form-item>
          <div class="item_wrap">
            <a-form-item label="籍贯：">
              <span v-if="!isEdit" class="item_con">{{identityForm.pgAddressName}}</span>
              <Province 
                v-else
                style="width:100%" 
                @regionCity='getPgAddressCode' 
                pgPlaceholder='请选择您的籍贯（选填）'
                addressType = "1"
                :addressValue="pgAddressValue"
                v-decorator="[
                  'pgAddressName',
                  { rules: [{ required: false, message: '请选择您的籍贯' }] },
                ]" ></Province>
            </a-form-item>
            <a-form-item label="所在行业：" v-if="userType==2 || userType==4">
              <span v-if="!isEdit" class="item_con">{{identityForm.industry ? identityForm.industry+'-'+identityForm.industrySub : ''}}</span>
              <a-cascader 
                v-else
                :field-names="{ label: 'name', value: 'code', children: 'children' }"
                :options="industryArr" 
                :load-data="getIndustryList1"
                @change="changeIndustry"
                placeholder="请选择行业"
                v-decorator="[
                  'industry',
                  { rules: [{ required: true, message: '请选择行业' }] },
                ]" />
            </a-form-item>
            <a-form-item label="民族：">
              <span v-if="!isEdit" class="item_con">{{identityForm.nation}}</span>
              <a-select 
                v-else
                placeholder="请选择民族（选填）" 
                v-decorator="[
                  'nationCode',
                  { rules: [{ required: false, message: '请选择民族' }] },
                ]" 
                @change="changeNation">
                <a-select-option 
                  v-for="(item, index) in nationArr" 
                  :key="index" 
                  :value="item.code">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="职业：" v-if="userType==2 || userType==4">
              <span v-if="!isEdit" class="item_con">{{identityForm.occupationName}}</span>
              <a-select 
                v-else
                placeholder="请选择职业" 
                v-decorator="[
                  'occupationCode',
                  { rules: [{ required: true, message: '请选择职业' }] },
                ]" 
                @focus="focusOccupation"
                @change="changeOccupation">
                <a-select-option 
                  v-for="(item, index) in occupationArr" 
                  :key="index" 
                  :value="item.code">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="学历：" key="1">
              <span v-if="!isEdit" class="item_con">{{identityForm.degree}}</span>
              <a-select 
                v-else
                placeholder="请选择学历" 
                v-decorator="[
                  'degreeCode',
                  { rules: [{ required: true, message: '请选择学历' }] },
                ]" 
                @change="changeDegree">
                <a-select-option 
                  v-for="(item, index) in degreeArr" 
                  :key="index"
                  :value="item.code" >{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="工作单位：" v-if="userType==2 || userType==4">
              <span v-if="!isEdit" class="item_con">{{identityForm.work}}</span>
              <a-select 
                v-else
                mode="combobox"
                placeholder="请选择您的工作单位" 
                @change="changeWork"
                v-decorator="[
                  'workCom',
                  { rules: [{ required: true, message: '请选择工作单位' }] },
                ]" >
                <a-select-option 
                  v-for="(item, index) in workArr" 
                  :key="index" 
                  :value="item.name">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="所在地：">
              <span v-if="!isEdit" class="item_con">{{identityForm.locationName}}</span>
              <Province 
                v-else
                style="width:100%" 
                @regionCity='getAddressCode' 
                pgPlaceholder='请输入所在地'
                addressType = "2"
                :addressValue="addressValue"
                v-decorator="[
                  'address',
                  { rules: [{ required: true, message: '请输入所在地' }] },
                ]" ></Province>
            </a-form-item>
            <a-form-item label="孩子生日：" v-if="userType==1 || userType==4">
              <span v-if="!isEdit" class="item_con">{{identityForm.childBirthday}}</span>
              <a-date-picker 
                v-else
                placeholder="请选择孩子生日" 
                v-decorator="[
                  'childBirthday',
                  { rules: [{ required: true, message: '请选择孩子生日' }] },
                ]" 
                @change="changeBirthday" />
            </a-form-item>
            <a-form-item label="邮箱：">
              <span v-if="!isEdit" class="item_con">{{identityForm.email}}</span>
              <a-input 
                v-else
                placeholder="请输入邮箱（选填）"
                v-decorator="[
                  'email',
                  { rules: [
                    { required: false, message: '请输入邮箱' },
                    { pattern: $regular.email.reg, message: $regular.email.msg },
                  ] },
                ]"  />
            </a-form-item>
            <a-form-item label="工作年限：" v-if="userType==2 || userType==4">
              <span v-if="!isEdit" class="item_con">{{identityForm.workYears}}</span>
              <a-input-number 
                v-else
                placeholder="请输入工作年限"
                :min="0" 
                :max="100000"
                v-decorator="[
                'workYears',
                { rules: [
                  { required: true, message: '请输入工作年限' },
                ] },
              ]"/>
            </a-form-item>
          </div>
          <a-form-item v-if="!!isEdit" class="item_button"> 
            <div class="all-btn-blue" @click="onCancel">返回查看</div>
            <div class="all-btn-small" @click="check">提交<a-icon v-if="isLoading" type="loading" /></div>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <!-- 加载 -->
    <div class="load_box" v-if="!showPage">
      <a-spin class="load" size='large' />
    </div>
  </div>
</template>

<script>
import Province from '@/components/provinceCity'
export default {
  // 可用组件的哈希表
  components: { Province },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      form: this.$form.createForm(this, { name: 'dynamic_rule' }),

      showPage: false, //展示页面
      identityForm: null, //身份认证信息
      isAuthentication: false, //是否已认证
      isEdit: true, //是否编辑
      isLoading: false, //加载

      userType: 0, //用户类型 1家长 2从业人员 4家长和从业人员
      idCardVerify: [
        { required: true, message: '请输入您的身份证' },
        { pattern: this.$regular.idCard.reg, message: this.$regular.idCard.msg }
      ], //证件校验
      sexDisabled: false, //性别是否禁选
      pgAddressValue:[], //籍贯省市区
      addressValue:[], //所在地省市区

      degree: '', //学历
      industry: '', //所在行业大类
      industrySub: '', //所在行业小类
      industryName: '', //所在行业
      locationName: '', //所在地
      nation: '', //民族
      occupationName: '', //职业
      pgAddressName: '', //籍贯
      work: '', //工作单位
      workIndex: -1, //工作单位index
      workCom: null, //工作单位code
      childBirthday: '', //孩子生日
      dateFormat: 'YYYY-MM-DD',

      identityArray: [
        {
          id: 1,
          name: '中国居民身份证'
        },
        {
          id: 2,
          name: '港澳台居民居住证'
        },
        {
          id: 3,
          name: '护照'
        },
      ], //证件类型
      sexArray: [
        {
          id: '1',
          name: '男'
        },
        {
          id: '2',
          name: '女'
        },
      ], //性别
      
      industryArr: [], //所在行业列表
      nationArr: [], // 民族列表
      occupationArr: [], // 职业列表
      degreeArr: [], // 学历列表
      workArr: [], // 工作单位列表
    }
  },
  // 事件处理器
  methods: {
    // 取消弹窗
    onCancel(){
			this.$emit('setCancelModal')
    },
    // 初始化列表
    async initList() {
      // 获取用户信息
      await this.getUserInfo();
      // 认证身份
      let userType = this.$store.state.userInfo.userType

      // 行业-大类
      await this.getIndustryList();
      // 民族
      await this.getNationList();
      // 学历
      await this.getDegreeList();

      if (!!userType) {
        // 获取身份认证信息
        await this.getIdentityAuthen();
      } else {
        // 未认证
        this.isAuthentication = false
        this.showPage = true; //显示页面
      }
      
    },
    // 获取身份认证
    async getIdentityAuthen() {
      this.showPage = false; //隐藏页面
      this.$ajax({
        url: '/hxclass-pc/user/info/infodetail',
        method: 'GET',
        params: {
          userId: this.$store.state.userInfo.userId
        }
      }).then(async(res) => {
        // console.log(res)
        if (res.code == 200 && res.success) {
          this.identityForm = res.data
          this.showPage = true; //显示页面
          if (this.identityForm) {
            // 工作单位列表
            this.getWorkList(this.identityForm.work);
            this.isAuthentication = true //已认证
            // 回显
            await this.showIdentityAuthen();
            this.isEdit = false //是否编辑信息
          }
        }
      })
    },
    // 回显身份认证
    async showIdentityAuthen() {
      // console.log(this.identityForm)
      // 未认证
      if (!this.isAuthentication) {
        return
      }
      //用户类型 1家长 2从业人员 4家长和从业人员
      let userType = []
      this.userType = this.identityForm.userType
      if (this.identityForm.userType == 1) {
        userType = ['2']
      } else if (this.identityForm.userType == 2) {
        userType = ['1']
      } else if (this.identityForm.userType == 4) {
        userType = ['1', '2']
      }

      // 从业者
      if (this.userType==2 || this.userType==4) {
        // 所在行业-小类
        await this.getIndustryList1(undefined, this.identityForm.industryCode);
        // 职业
        await this.getOccupationList(this.identityForm.industryCodeSub);
      }

      // 籍贯
      if (this.identityForm.pgAddressProvincial && this.identityForm.pgAddressCity) {
        this.pgAddressValue = [this.identityForm.pgAddressProvincial, this.identityForm.pgAddressCity];
      }
      // 所在地
      this.addressValue = [this.identityForm.addressProvincial, this.identityForm.addressCity, this.identityForm.addressArea];
      // console.log(this.pgAddressValue, this.addressValue)

      this.$nextTick(() => {
        this.form.setFieldsValue({
          // name: this.identityForm.name, //姓名
          // idType: this.identityForm.idType, //证件类型
          // idNo: this.identityForm.idNo, //证件号
          // sex: this.identityForm.sex, //性别
          type: userType, //用户类型
          pgAddressName: this.pgAddressValue, //籍贯
          nationCode: this.identityForm.nationCode ? parseInt(this.identityForm.nationCode) : undefined, //民族
          degreeCode: this.identityForm.degreeCode ? parseInt(this.identityForm.degreeCode) : undefined, //学历
          address: this.addressValue, //所在地
          email: this.identityForm.email, //邮箱
        });

        this.pgAddressName = this.identityForm.pgAddressName || ''; //籍贯
        this.nation = this.identityForm.nation || ''; //民族
        this.degree = this.identityForm.degree || ''; //学历
        this.locationName = this.identityForm.locationName || ''; //所在地

        // 从业者
        if (this.userType==2 || this.userType==4) {
          this.form.setFieldsValue({
            industry: [parseInt(this.identityForm.industryCode), parseInt(this.identityForm.industryCodeSub)], //行业
            occupationCode: this.identityForm.occupationCode ? parseInt(this.identityForm.occupationCode) : undefined, //职业
            workCom: this.identityForm.work, //工作单位
            workYears: this.identityForm.workYears, //年限
          });

          this.industryName = this.identityForm.industry+'-'+this.identityForm.industrySub || ''; //籍贯
          this.industry = this.identityForm.industry || ''; //所在行业大类
          this.industrySub = this.identityForm.industrySub || ''; //所在行业小类
          this.occupationName = this.identityForm.occupationName || ''; //职业
          this.workCom = this.identityForm.workCom || null; //工作单位code
        }

        // 家长
        if (this.userType==1 || this.userType==4) {
          this.form.setFieldsValue({
            childBirthday: this.identityForm.childBirthday, //孩子生日
          });
          this.childBirthday = this.identityForm.childBirthday || ''; //孩子生日
        }
        // console.log(this.form.getFieldsValue(), this.workArr)
      });
    },
    // 修改身份认证
    editIdentityAuthen() {
      // this.isAuthentication = true //已认证
      this.isEdit = true //修改
      // 回显身份认证
      this.showIdentityAuthen();
    },
    // 获取用户信息
    async getUserInfo() {
      this.$ajax({
        url: "/hxclass-pc/user/info",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.obj = res.data;
          this.obj.isLogin = true;
          this.$store.commit("updateUserInfo", this.obj);
        }
      });
    },
    // 获取籍贯
    getPgAddressCode(data, name){
      // console.log(data, name)
      this.form.setFieldsValue({['pgAddressName']: data})
      this.pgAddressValue = data
      this.pgAddressName =  name //籍贯
    },
    // 地址列表
    getPgAddressList(){
      let code = 0
      this.$ajax({
        url: "/hxclass-pc/province-pc/pc/province/" + code,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.industryArr = []
          res.data.map((item, index) => {
            this.industryArr.push({
              ...item,
              isLeaf: false
            })
          });
        }
      });
    },
    // 所在行业列表 - 大类
    getIndustryList() {
      this.$ajax({
        url: "/hxclass-pc/nation/industry/list",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.industryArr = []
          res.data.map((item, index) => {
            this.industryArr.push({
              ...item,
              isLeaf: false
            })
          });
        }
      });
    },
    // 所在行业列表 - 小类
    getIndustryList1(selectedOptions, code) {
      // console.log(selectedOptions)
      let pcode = ''
      if (!!selectedOptions) {
        selectedOptions[0].loading = true;
        // 大类id
        pcode = selectedOptions[0].code;
      }else {
        // 大类id
        pcode = code;
      }
      
      this.$ajax({
        url: "/hxclass-pc/nation/industry/list?pcode=" + pcode,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (!!selectedOptions) {
            selectedOptions[0].loading = false;
            selectedOptions[0].children = res.data;
            this.industryArr = [...this.industryArr];
          } else {
            this.industryArr.map((item, index) => {
              if (item.code == code) {
                this.$set(this.industryArr[index], 'children', res.data)
              }
            })
          }
        }
      });
    },
    // 民族列表
    getNationList() {
      this.$ajax({
        url: "/hxclass-pc/nation/list",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.nationArr = res.data;
        }
      });
    },
    // 职业列表
    getOccupationList(code) {
      this.$ajax({
        url: "/hxclass-pc/nation/position/list",
        params: {
          industryId: code
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.occupationArr = res.data;
        }
      });
    },
    // 学历列表
    getDegreeList() {
      this.$ajax({
        url: "/hxclass-pc/nation/degree/list",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.degreeArr = res.data;
        }
      });
    },
    // 工作单位列表
    getWorkList(keyword) {
      // 工作单位列表
      this.$ajax({
        url: "/hxclass-pc/work/sort/list",
        params: {
          keyword: keyword
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.workArr = []
          let workArr = []
          res.data.map((item, index) => {
            workArr.push(...item.sortDetailVOList)
            this.workArr.push(...item.sortDetailVOList)
          })
          this.$set(this, 'workArr', workArr)
        }
      });
    },
    // 获取所在地
    getAddressCode(data, name) {
      this.form.setFieldsValue({['address']: data})
      this.addressValue = data
      this.locationName = name
    },
    // 证件类型选择
    changeIdType(index) {
      // 中国居民身份证
      if (index == 1) {
        this.idCardVerify = [
          { required: true, message: '请输入您的身份证' },
          { pattern: this.$regular.idCard.reg, message: this.$regular.idCard.msg }
        ]
      } 
      // 港澳台居民居住证
      else if (index == 2) {
        this.idCardVerify = [
          { required: true, message: '请输入您的港澳台居民居住证' },
        ]
      }
      // 护照
      else if (index == 3) {
        this.idCardVerify = [
          { required: true, message: '请输入您的护照' },
        ]
      }
      this.$nextTick(() => {
        this.form.validateFields(['idNo'], { force: true });
      });
    },
    // 证件信息
    changeIdNo(e) {
      let idType =  this.form.getFieldsValue(['idType']);
      // console.log(e.target.value, idType)
      let idNo = e.target.value
      let sex = ''
      // if (this.idType !== 0) {
      //   this.sexDisabled = false
      //   return
      // }
      // console.log(this.$regular.idCard.reg.test(idNo), idType !== 0)
      if (this.$regular.idCard.reg.test(idNo) && idType !== 0) {
        this.sexDisabled = true
        if (parseInt(idNo.substr(16, 1)) % 2 == 1) {
          sex = '1'
        } else {
          sex = '2'
        }
        // console.log(sex)
        this.form.setFieldsValue({['sex']: sex})
      } else {
        this.sexDisabled = false
      }
    },
    // 身份选择
    changeType(e) {
      // console.log(e)
      // 1家长 2从业人员 4家长和从业人员
      if (e.length == 0) {
        this.userType = 0 //未选中
      }
      if (e.length == 1) {
        if (e[0] == '1') {
          this.userType = 2 //从业人员
        } 
        else if (e[0] == '2') {
          this.userType = 1 //家长
        }
      }
      else if (e.length == 2) {
        this.userType = 4
      }

      // 回显信息
      this.showIdentityAuthen1();
    },
    // 回显切换类型后的身份信息
    showIdentityAuthen1() {
      // console.log(this.identityForm, this.userType, this.degreeArr, this.identityForm.degreeCode ? parseInt(this.identityForm.degreeCode):'')
      // 未认证
      if (!this.isAuthentication) {
        return
      }
      this.$nextTick(() => {
        this.form.setFieldsValue({
          // pgAddressName: this.pgAddressValue, //籍贯
          nationCode: this.identityForm.nationCode ? parseInt(this.identityForm.nationCode) : '', //民族
          degreeCode: this.identityForm.degreeCode ? parseInt(this.identityForm.degreeCode) : '', //学历
          // address: this.addressValue, //所在地
          email: this.identityForm.email, //邮箱
        });

        // 从业者
        if (this.userType==2 || this.userType==4) {
          this.form.setFieldsValue({
            industry: [parseInt(this.identityForm.industryCode), parseInt(this.identityForm.industryCodeSub)], //行业
            occupationCode: this.identityForm.occupationCode ? parseInt(this.identityForm.occupationCode) : '', //职业
            workCom: this.identityForm.work, //工作单位
            workYears: this.identityForm.workYears, //年限
          });
        }

        // 家长
        if (this.userType==1 || this.userType==4) {
          this.form.setFieldsValue({
            childBirthday: this.identityForm.childBirthday, //孩子生日
          });
        }
        // console.log(this.form.getFieldsValue())
      });
    },
    // 行业选择
    changeIndustry(value, option) {
      // console.log('所在行业', value, option)
      if (value.length == 0) {
        this.industry = '' //所在行业大类
        this.industrySub = '' //所在行业小类
        this.industryName = '' //所在行业
        return
      }
      this.industry = option[0].name //所在行业大类
      this.industrySub = option[1].name //所在行业小类
      this.industryName = option[0].name + '-' + option[1].name //所在行业
      // 职业列表
      this.getOccupationList(value[1]);
    },
    // 民族选择
    changeNation(value, option) {
      // console.log(value, option)
      this.nation = this.nationArr[option.key].name
    },
    // 职业获得焦点
    focusOccupation() {
      let industry = this.form.getFieldsValue(['industry']).industry
      // console.log(industry)
      if (!industry || industry.length==0) {
        this.$message.warning('请先选择行业');
      }
    },
    // 职业选择
    changeOccupation(value, option) {
      // console.log(value, option)
      this.occupationName = this.occupationArr[option.key].name
    },
    // 学历选择
    changeDegree(value, option) {
      // console.log(value, option)
      this.degree = this.degreeArr[option.key].name
    },
    // 工作单位选择
    changeWork(value, option) {
      // console.log(value, option)
      let keyword = value.trim();
      // console.log(keyword)
      if (keyword == '') {
        return
      }
      // 工作单位列表
      this.$ajax({
        url: "/hxclass-pc/work/sort/list",
        params: {
          keyword: keyword
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.workCom = null; //回显code为null
          this.workArr = []
          let workArr = []
          res.data.map((item, index) => {
            workArr.push(...item.sortDetailVOList)
            this.workArr.push(...item.sortDetailVOList)
          })
          this.$set(this, 'workArr', workArr)
          // 赋值
          if (this.workArr.length !== 0) {
            this.work = this.workArr[option.key].name
            this.workIndex = option.key
          } else {
            this.work = option.key
            this.workIndex = -1
          }
          // console.log(this.workArr.length, this.work, this.workIndex)
        }
      });
    },
    // 孩子生日选择
    changeBirthday(date, value) {
      // console.log(date, value)
      this.form.setFieldsValue({['childBirthday']: value})
      this.childBirthday = value
    },
    // 保存
    check() {
      if ( this.isLoading ) {return}
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log(values)
          let params = {}

          // 未认证
          if (!this.isAuthentication) {
            params.name = values.name //姓名
            params.idType = values.idType //  证件类型
            params.idNo = values.idNo.toUpperCase() //证件号码
            params.sex = values.sex //性别
          }
          params.userType = parseInt(this.userType) //用户类型
          if (values.pgAddressName && values.pgAddressName.length) {
            params.pgAddressProvincial = values.pgAddressName[0] //籍贯-省
            params.pgAddressCity = values.pgAddressName[1] //籍贯-市
            params.pgAddressName = this.pgAddressName //籍贯
          }
          params.nationCode = values.nationCode //民族code
          params.nation = this.nation //民族
          params.degreeCode = values.degreeCode //学历code
          params.degree = this.degree //学历
          params.email = values.email //邮箱
          params.addressProvincial = values.address[0] //所在地-省
          params.addressCity = values.address[1] //所在地-市
          params.addressArea = values.address[2] //所在地-区
          params.locationName = this.locationName //所在地

          if (this.userType==2 || this.userType==4) {
            params.industryCode = values.industry[0] //所在行业code-大类
            params.industryCodeSub = values.industry[1] //所在行业code-小类
            params.industryName = this.industryName //所在行业
            params.industry = this.industry //所在行业-大类
            params.industrySub = this.industrySub //所在行业-小类
            params.occupationCode = values.occupationCode //职业code
            params.occupationName = this.occupationName //职业
            params.workYears = values.workYears //工作年限
            params.work = values.workCom //工作单位
            if (this.workIndex == -1) {
              params.workCom = this.workCom ? this.workCom : '' //工作单位code
            } else {
              params.workCom = this.workArr[this.workIndex].workUnitId
            }
          }

          if (this.userType==1 || this.userType==4) {
            params.childBirthday = this.childBirthday //孩子生日
          }

          // console.log(params)
          // return
          
          this.isLoading = true
          this.$ajax({
            url: "/hxclass-pc/user/info/add",
            method: 'POST', 
            params: params
          }).then((res) => {
            this.isLoading = false
            if (res.code == 200 && res.success) {
              this.$message.success('认证成功');
              this.$emit('setSuccessModal')
              this.initList();

              // setTimeout(()=>{
              //   this.$router.go(0)
              // },1000)
              // // 初始化列表
            } else {
              this.$message.error(res.message);
            }
          });

        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    // 初始化列表
    this.initList();
  },
  // 生命周期-实例挂载后调用
  mounted () { 
    this.$nextTick(() => {
      this.form.setFieldsValue({['idType']: 1})
    })
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  * {box-sizing: border-box;}
  /deep/.ant-form-item-required::before {
    display: none;
  }
  /deep/.ant-form-explain {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFang HK-Regular, PingFang HK;
    font-weight: 400;
    color: #FF0000;
    &::before {
      content: '';
      display: inline-block;
      width: 13px;
      height: 13px;
      background-image: url('~@/assets/image/personalCenter/2022050909.png');
      background-repeat: no-repeat;
      background-size: 13px;
      margin-right: 4px;
    }
  }
  /deep/.ant-input, /deep/.ant-select-selection {
    border: 1px solid #C4C4C4;
  }
  /deep/.ant-form-item {
    display: flex;
  }
  /deep/.ant-form-item-label {
    width: 104px;
    text-align: left;
  }
  /deep/.ant-form-item-label>label {
    font-size: 18px;
    font-family: PingFang HK-Medium, PingFang HK;
    font-weight: 500;
    color: #555;
    line-height: 27px;
  }
  .main-wrap {
    position: relative;
    min-height: 100%;
    .box {
      .box_title {
        .title_info1 {
          display: inline-flex;
          align-items: center;
          font-size: 24px;
          font-family: PingFang HK-Medium, PingFang HK;
          font-weight: 500;
          color: #15B7DD;
          line-height: 36px;
          &::before {
            content: '';
            display: inline-block;
            width: 4px;
            height: 19px;
            line-height: 36px;
            background: #15B7DD;
            border-radius: 2px;
            margin-right: 8px;
          }
        }
        .title_info2 {
          font-size: 18px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #15B7DD;
          line-height: 27px;
          margin-left: 9px;
        }
      }
      .box_input {
        .item_con {
          font-size: 16px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #333333;
          line-height: 27px;
        }
      }
    }
    // 实名认证
    .box1 {
      position: relative;
      .box_hint {
        margin-top: 12px;
        .hint_icon {
          width: 13px;
        }
        .hint_txt {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 21px;
          margin-left: 4px;
        }
      }
      .box_label {
        position: absolute;
        top: 30px;
        right: 0;
        img {
          width: 100px;
        }
      }
      .box_input {
        margin-top: 24px;
        /deep/.ant-input {
          width: 420px;
          height: 41px;
        }
        /deep/.ant-form-item {
          margin-bottom: 20px;
        }
        /deep/.ant-radio-wrapper {
          font-size: 18px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #333333;
          margin-right: 41px;
          >span {
            vertical-align: middle;
          }
        }
        /deep/.ant-radio-inner {
          border-color: #333333;
        }
        .item_idType {
          /deep/.ant-select {
            border: 0;
          }
          /deep/.ant-select-selection {
            // border: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          /deep/.ant-select-selection__rendered, /deep/.ant-select-selection--single, /deep/.ant-select-selection-selected-value {
            width: 150px;
            height: 41px;
            line-height: 41px;
          }
          /deep/.ant-form-item-children {
            display: flex;
            // width: 400px;
            // height: 43px;
            // line-height: 43px;
            border-radius: 4px;
            // border: 1px solid #C4C4C4;
            .ant-form-item {
              margin: 0;
            }
          }
          /deep/.ant-input {
            width: 270px;
            // border: 0;
            border-left: 0;
            // border-left: 1px solid #C4C4C4;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: -1px;
          }
        }
      }
    }
    // 个人信息
    .box2 {
      position: relative;
      margin-top: 54px;
      .box_edit {
        position: absolute;
        top: 0;
        right: 0;
        display: inline-flex;
        align-items: center;
        box-shadow: 0px 4px 4px 0px rgba(49,188,221,0.1);
        border-radius: 20px;
        border: 1px solid #15B7DD;
        padding: 8px 20px;
        cursor: pointer;
        .edit_icon {
          width: 18px;
        }
        .edit_txt {
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #15B7DD;
          line-height: 24px;
          margin-left: 4px;
        }
      }
      .box_input {
        margin-top: 32px;
        .item_con {
          display: inline-block;
          min-width: 300px;
        }
        .form_item1 {
          .item_info {
            font-size: 18px;
            font-family: PingFang HK-Regular, PingFang HK;
            font-weight: 400;
            color: #15B7DD;
            line-height: 27px;
          }
          .ant-row, /deep/.ant-form-explain {
            margin-left: -104px;
          }
        }
        /deep/.ant-input {
          width: 300px;
          height: 41px;
        }
        /deep/.ant-select, /deep/.ant-select-selection--single, /deep/.ant-select-selection__rendered {
          width: 300px;
          height: 41px;
          line-height: 41px;
        }
        .item_wrap {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          /deep/.ant-form-item {
            margin-right: 33px;
            margin-bottom: 20px;
          }
        }
        /deep/.ant-input-number {
          border: 1px solid #C4C4C4;
        }
        /deep/.ant-input-number, /deep/.ant-input-number-input {
          width: 300px;
          height: 41px;
        }
        .item_button {
          display: flex;
          width: 100%;
          margin-top: 63px;
          /deep/.ant-col {
            width: 100%;
          }
          /deep/.ant-form-item-control {
            width: 100%;
          }
          /deep/.ant-form-item-children {
            display: flex;
            justify-content: center;
          }
          .all-btn-blue, .all-btn-small {
            width: 138px;
            height: 40px;
            font-size: 16px;
            .anticon {
              margin-left: 8px;
            }
          }
          .all-btn-small{
            margin-left: 54px;
          }
          .all-btn-small.ant-btn:hover, .ant-btn:focus, .ant-btn:active, .ant-btn.active {
            text-decoration: none;
            background: @theme;
          }
        }
      }
    }
    // 加载
    .load_box {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }
  // 小屏分辨率内容区呈现1200px布局
  @media screen and (max-width: 16000px) {
    /deep/.ant-form-item-label {
      width: 85px;
    }
    /deep/.ant-form-item-label > label {
      font-size: 16px;
    }
    .main-wrap {
      .box {
        .box_title {
          .title_info1 {
            font-size: 22px;
          }
          .title_info2 {
            font-size: 16px;
          }
        }
      }
      .box1 {
        .box_hint {
          .hint_icon {
            width: 11px;
            height: 11px;
          }
          .hint_txt {
            font-size: 12px;
          }
        }
        .box_input {
          /deep/.ant-radio-wrapper {
            font-size: 16px;
          }
        }
      }
      .box2 {
        margin-top: 30px;
        .box_input {
          margin-top: 24px;
          .form_item1 {
            .item_info {
              font-size: 16px;
            }
            .item_con {
              margin-right: 10px;
            }
            .ant-row, /deep/.ant-form-explain {
              margin-left: -93px;
            }
          }
          /deep/.ant-input {
            width: 230px;
            height: 41px;
          }
          /deep/.ant-select, 
          /deep/.ant-select-selection--single, 
          /deep/.ant-select-selection__rendered {
            width: 230px;
            height: 41px;
          }
          /deep/.ant-input-number, /deep/.ant-input-number-input {
            width: 230px;
            height: 41px;
          }
          .item_wrap .ant-form-item {
            margin-right: 0;
          }
          .item_button {
            margin-top: 26px;
          }
        }
      }
    }
  }
</style>
